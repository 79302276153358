import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'gatsby'
import SwiperCore, {
  Navigation,
  Pagination,
  Parallax,
  EffectFade,
  Autoplay
} from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { thumparallax } from "common/thumparallax";
import removeSlashFromPagination from "common/removeSlashpagination";

import EsdmSliderData from "data/sections/esdm-slider-data.json" 

SwiperCore.use([Navigation, Pagination, Parallax, EffectFade, Autoplay]);

const EsdmTrainings = ({scrollToContactForm}) => {
  const [load, setLoad] = React.useState(true);

  const swiper1Ref = React.useRef(null);
  const swiper2Ref = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false);
      removeSlashFromPagination();
      thumparallax();
    }, 1000);
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  const handleSwiper1 = (swiper) => {
    swiper1Ref.current = swiper;
    // console.log(swiper1Ref.current);
  }

  const handleSwiper2 = (swiper) => {
    swiper2Ref.current = swiper;
    // console.log(swiper2Ref.current);
  }

  const syncSwipers = (swiper) => {
    // console.log(swiper.slideTo);

    console.log(swiper.passedParams.a11y.id+'----');

    


    // console.log(swiper1Ref.current.passedParams.a11y.id);
    // console.log(swiper2Ref.current.passedParams.a11y.id);
  };

  return (
    <div className="section-padding esdm-trainings">
    <h4 className="words chars splitting main-title wow mb-80" data-splitting>
      Learning Programs & Certifications
    </h4>
    <section className="blog-crv sub-bg">
      <h2 style={{ display: "none" }}>&nbsp;</h2>
      <div className="stories">
        <div className="container-fluid position-relative">
          <div className="row justify-content-around">
            <div className="col-lg-5 no-padding">
              {!load ? (
                <Swiper
                  speed={800}
                  effect="fade"
                  allowTouchMove={false}
                  spaceBetween={0}
                  a11y={{
                    id:'swiper1'
                  }}
                  loop={true}
                  parallax={true}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  pagination={{
                    type: "fraction",
                    clickable: true,
                    el: paginationRef.current,
                  }}
                  onSlideChange={(swiper) => syncSwipers(swiper)}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.params.pagination.el = paginationRef.current;
                    
                    handleSwiper1(swiper);
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].childNodes[0].setAttribute(
                          "data-swiper-parallax",
                          0.75 * swiper.width
                        );
                      }

                     

                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      swiper.params.pagination.el = paginationRef.current;

                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();

                      swiper.pagination.destroy();
                      swiper.pagination.init();
                      swiper.pagination.update();
                    });
                  }}
                  className="swiper-wrapper swiper-container swiper-img"
                  slidesPerView={1}
                >
                  {
                   EsdmSliderData.map((slide,index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                    <div className="item wow fadeIn" data-wow-delay=".3s">
                      <div className="img">
                        <img
                          className="thumparallax"
                          src={slide.image}
                          alt={slide.title}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                    ))
                  }
                </Swiper>
              ) : null}
            </div>
            <div className="col-lg-6 no-padding valign">
              {!load ? (
                <Swiper
                  speed={800}
                  spaceBetween={0}
                  allowTouchMove={false}
                  a11y={{
                    id:'swiper2'
                  }}
                  loop={true}
                  
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  pagination={{
                    type: "fraction",
                    clickable: true,
                    el: paginationRef.current,
                  }}
                  onSlideChange={(swiper) => syncSwipers(swiper)}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.params.pagination.el = paginationRef.current;
                    handleSwiper2(swiper);
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].childNodes[0].setAttribute(
                          "data-swiper-parallax",
                          0.75 * swiper.width
                        );
                      }

                      
                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      swiper.params.pagination.el = paginationRef.current;

                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();

                      swiper.pagination.destroy();
                      swiper.pagination.init();
                      swiper.pagination.update();
                    });
                  }}
                  className="swiper-wrapper swiper-container swiper-content"
                  slidesPerView={1}
                >
                  {
                    EsdmSliderData.map((slide,index) => (
                      <SwiperSlide className="swiper-slide" key={index}>
                    <div className="item wow fadeIn" data-wow-delay=".6s">
                      <div className="content">
                        {/* <div className="tags">
                          <Link to="/blog/blog-dark">Trending</Link>
                        </div>
                        <div className="info">
                          <Link to="/blog/blog-dark">
                            <i className="far fa-clock"></i>
                            06 Aug 2022
                          </Link>
                          <a href="#0">by Alex Morgan</a>
                        </div> */}
                        <div className="title">
                          <h5 className="mb-20">
                            <div className="custom-butn" onClick={() => scrollToContactForm()}>
                              {slide.title}
                            </div>
                          </h5>
                        </div>
                        <div className="text">
                          {
                            slide.content.map((data,index) => (
                              <p className="mb-20" key={index}>
                              {
                                data
                              }
                            </p>
                            ))
                          }
                        </div>
                        <div>
                          <button className="butn bord curve wow fadeInUp d-inline-block bg-white text-black custom-butn" to={slide.buttonUrl} onClick={() => scrollToContactForm()}>
                            {slide.buttonText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                    ))
                  }
                </Swiper>
              ) : null}
            </div>
          </div>

          <div className="controls esdm-slider-ctrls d-none">
            <div
              ref={navigationNextRef}
              className="swiper-button-next swiper-nav-ctrl next-ctrl d-none"
            >
              <i className="fas fa-caret-up"></i>
            </div>
            <div
              ref={navigationPrevRef}
              className="swiper-button-prev swiper-nav-ctrl prev-ctrl d-none"
            >
              <i className="fas fa-caret-down"></i>
            </div>
            <div className="swiper-pagination" ref={paginationRef}></div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default EsdmTrainings;
